import React from "react";
import { Container} from 'react-bootstrap';
import Header from "./Header";
import Disclosures from "./disclosures";

const DisclosuresHtml = () => {
  return (
    <>
      <Header />
      <main>
        <Container fluid>
          <div className='disclosures-html'><Disclosures /></div>
        </Container>
      </main>
    </>
  );
};

export default DisclosuresHtml;