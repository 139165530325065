import React from "react";

const ConsentCommunications = () => {
  return (
    <>
      <h3 className='text-center'>CONSENT TO RECEIVE COMMUNICATIONS ELECTRONICALLY</h3>
      <p><strong>Please read this consent (“Consent”) completely.</strong> This Consent covers electronic records, as specified below, applicable to your deposit accounts (“Account(s)”) opened online with {window.bankName} (the “Bank”). The words “we,” “us,” “our” and other similar terms refer to {window.bankName}, and the words “you,” “your” and other similar terms mean you, the individual(s) or entity identified on the Account(s). The term “consumer account” will refer to an account that has been primarily established for personal, family or household purposes. If your Account is a joint account, we will consider the Communication (defined below) to have been given to all joint account holders when made available to you pursuant to this Consent. If you are not an owner of the account and are reviewing this Consent in your capacity as agent for the account owner, you are consenting to receive electronic records, as provided herein, on behalf of the account owner.</p>
      <p>If you choose not to agree by cancelling this application, you have the option to open your new deposit account(s) in one of our branches. Your decision to cancel this application will not limit our ability to otherwise communicate with you electronically, to the extent not prohibited by applicable law.</p>
      <p><b>Electronic Records</b><br/>By continuing this application, you understand and agree to be bound by the terms and conditions of this Consent, and authorize us to provide to you in electronic format the following communications (collectively referred to herein as “Communications”) regarding your Accounts:</p>
      <ul>
        <li>This Consent;</li>
        <li>Account Agreement, General Account Disclosures, including but not limited to fee schedules, service agreements, as applicable (collectively, “Deposit Agreement”). The Deposit Agreement contains the terms and conditions governing the deposit account(s) and related services offered by the Bank to you. It also contains information that the Bank is required to disclose under the Electronic Funds Transfer Act, the Truth in Savings Act, the Expedited Funds Availability Act and the USA Patriot Act, as applicable.</li>
        <li>Initial privacy notices provided pursuant to the Gramm-Leach-Bliley Act and applicable state law.</li>
      </ul>
      <p>You agree that the above list is not exhaustive. We may present you with the opportunity to receive and confirm or accept additional documents, records or agreements in electronic form. For example, electronic agreements may be provided to you through such things as hyperlinks or “click-through” agreements on our web site. Your consent to or agreement with the electronic communication in these circumstances may occur by your clicking “agreed” or similar terms, or by your subsequent use of a product or service, or otherwise as may be specified in the communication or as provided by law (subject to any limitations set forth in the communication). Your signature and agreement may be obtained by us electronically and includes mouse clicks, key strokes, your use of passwords or other authentication systems, or as is otherwise set forth in the particular electronic communication.</p>
      <p>You agree not to contest the authorization for, or validity or enforceability of, our electronic records and documents, or the admissibility of copies thereof, under any applicable law relating to whether certain agreements, files or records are to be in writing or signed by the party to be bound thereby. Records and electronically “signed” documents, if introduced as evidence on paper in any judicial or other proceedings, will be admissible to the same extent and under the same conditions as other documentary business records. Upon our request, you agree to manually sign or place your signature on any paper original of any record or “signed” document which we provide to you containing your purported signature.</p>
      <p><b>Paper Copies</b><br/>
      You may obtain paper copies of any of the Communications we provide to you electronically by sending your written request to 801 S. Figueroa St. Los Angeles CA 90017. If you request a paper-based copy, we will provide the first copy to you free of Bank fees or charges. We will mail paper-based copies of a Communication to you (at the address shown on the Bank’s records) within 5 business days after the date we receive your request. Although we do not currently impose a fee or other charge for paper copies of Communications, we reserve the right to impose a fee or charge in the future and to change such fee at any time.</p>
      <p><b>Withdrawal of Consent</b><br/>To be effective, a withdrawal of your consent would have to be provided before the Communications are provided to you. Also, we would need a reasonable opportunity to respond to your consent withdrawal. To withdraw your consent, you would need to contact us by phone at 888-889-8369 or write to us at 801 S. Figueroa St. Los Angeles CA 90017. Your withdrawal will not affect the previously delivered electronic Communications. Your withdrawal of consent does not otherwise affect the terms of your Accounts or operate as a right to opt-out of receiving any other information or communications from us, electronically or in any other form. We do not currently impose any fee or other charge if you choose not to consent or if you withdraw your consent. However, we reserve the right to impose a fee in the future and to change such fee at any time.</p>
      <p><b>Hardware or Software Requirements</b><br/>
      In order to access, view, and retain electronic Communications, you must have:</p>
      <ul>
        <li>Access to a device (e.g., computer, smartphone, mobile device, tablet, etc.) suitable for connecting to the Internet, or downloading our mobile app with the Current Version (as defined below) of (i) an operating system, such as Windows, Mac OS, iOS or Android, and (ii) a web browser, such as Internet Explorer, Chrome, Safari or Firefox, that we support.<br/></li>
        <li>A connection to the Internet;<br/></li>
        <li>Local electronic storage capacity to retain Communications and/or a printer to print them;<br/></li>
        <li>A valid e-mail account and software to access it;<br/></li>
        <li>Software that enables you to view and display files in HTML and PDF format.</li>
      </ul>
      <p>By "Current Version," we mean a version of the software that is currently being supported by its publisher. We reserve the right to discontinue support of a Current Version of software or an operating system if, in our sole opinion, it suffers from a security flaw or other flaw that makes it unsuitable for use.</p>
      <p>If our hardware or software requirements change, and that change would create a material risk that you would not be able to access or retain electronic Communications, we will give you notice of the revised hardware or software requirements. Continuing to use this service after receiving notice of the change is reaffirmation of your consent.</p>
      <p><b>How the Service Works</b><br/>The Communications will be provided to you after you give your consent. This Consent does not include continuing or future Communications. Notwithstanding the foregoing, we reserve the right to deliver one or more Communications in paper form instead of electronic form by mailing a Communications to the last known mailing address on our records for you. In the event that we do so, we are in no way terminating this Consent and we may continue to provide Communications to you in electronic form.</p>
      <p><b>Email Address</b><br/>In order to ensure that we are able to provide you with the Communications, you must provide us with your current email address.</p>
      <p>We also reserve the right to change the terms and conditions of this disclosure and consent. If required by law, we will provide you with notice of any such termination or change, and request a new consent.</p>
      <p>If you download or print any confidential materials, such as your transaction history, be sure that you store them in a secure environment, just as you would paper-based bank records.</p>
    </>
  );
};

export default ConsentCommunications;