import React, {useState, useEffect} from 'react';
import { Container} from 'react-bootstrap';
import {MobileView} from 'react-device-detect';
import axios from "axios";
import Header from '../components/Header';
import Login from '../components/Login';
import FormSubmit from '../components/FormSubmit';

const serverConfig = require("../config/server.js");

const Signup = () => {

  const [token, setToken] = useState(null);
  const [curPageName, setCurPageName] = useState(null);
  const [step, setstep] = useState(1);
  const [steps, setSteps] = useState([]);

  const nextStep = (current_page) => {
    console.log(current_page, step);
    setstep(step + 1);
    if(current_page) {
      setCurPageName(current_page);
      console.log(current_page);
      console.log(steps.findIndex((item) => item.group === current_page));
      setstep((steps.findIndex((item) => item.group === current_page)) + 2);
    } else {
      setCurPageName(steps[step].group);
    }
  };

  const prevStep = () => {
    console.log(steps, step);
    setstep(step - 1);
    setCurPageName(steps[step-2].group);
  };

  const handleLogout = () => {
    sessionStorage.clear();
  }

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {//on refresh clear session storage
        sessionStorage.clear();
      }
    }
  }, []);

  useEffect(() => {
    if(token !== null){
      axios.post(serverConfig.api_base_url + "customer_entity_group_get",{token: token}).then(response=>{
        console.log('customer_entity_group_get response', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            let res = response.data.items;
            res.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            console.log(res);
            console.log(step);
            setSteps(res);
            //setCurPageName(res[0].group);
            //setCurPageName('documentation');
            //setstep(4);
            if(sessionStorage.getItem('curPage')){
              setCurPageName(sessionStorage.getItem('curPage'));
              console.log(res.findIndex((item) => item.group === sessionStorage.getItem('curPage')) + 1);
              setstep(res.findIndex((item) => item.group === sessionStorage.getItem('curPage')) + 1);
            } else {
              setCurPageName(res[0].group);
            }
          }
        } else if(response.data.status === 407){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('token'));
    console.log(token);
    setToken(sessionStorage.getItem('token'));
    console.log(sessionStorage.getItem('emailid'));
  }, [curPageName]);


  return(
    <>
      <Header isLoggedIn={(curPageName === 'login' || curPageName === '' || curPageName === null) ? false : true} />
      <MobileView>
        <main>
            {(token === "null" || token === null || token === undefined || token === '') && <>
              <Container fluid>
                <div className='page-title'>
                <h1>New Customer Onboarding</h1>
                </div>
              </Container>
              <Login nextStep={nextStep} />
            </>}

            {(token && curPageName && steps.length > 0) && <>
              <Container fluid>
                <div className='page-title'>
                  <h1>{steps[step-1].title}</h1>
                </div>
              </Container>
              <Container fluid className='step'>
                <div className='step-left'>
                  <div className="step-count">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="56" height="56">
                      <circle cx="28" cy="28" r="25" stroke="#cccccc" strokeWidth="6" fill="none" />
                      <circle cx="28" cy="28" r="25" stroke="#1ED800" strokeWidth="6" fill="none" strokeDashoffset={((steps.length - step)/steps.length) * 160} strokeDasharray="160" />
                    </svg>
                    <span>{step} of {steps.length}</span>
                  </div>
                </div>
                <div className='step-right'>
                  <span className='step-title'>
                    {steps[step-1].icon && <img src={steps[step-1].icon} />}
                    <strong>{steps[step-1].label}</strong>
                  </span>
                  {step < steps.length && <span className='next-step'>Next: <strong>{steps[step].label}</strong></span>}
                </div>
              </Container>
              <Container fluid>
                {/* {step}, {curPageName} */}
                <ul className='steps-progress'>
                  {steps.map((item, index) => {
                    let curClass;
                    if(step-1 === index){
                      curClass = 'active'
                    } else if(index < step){
                      curClass = 'completed'
                    }
                    return(<li key={index} className={curClass}><span></span></li>)
                  })}
                </ul>
              </Container>
              <FormSubmit prevStep={prevStep} nextStep={nextStep} token={token} curPageName={curPageName} stepNum={step} stepLength={steps.length} handleLogout={handleLogout} />
            </>}
        </main>
      </MobileView>
  </>
  )
}

export default Signup;
