import React, { useState } from "react";
import { Button } from 'react-bootstrap';

const StarRating = () => {
  const [rating, setRating] = useState(0);
  return (
    <div id="star-rating" className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <Button variant="star" type="button" key={index} className={index <= rating ? "on" : "off"} onClick={() => setRating(index)}></Button>
        );
      })}
    </div>
  );
};

export default StarRating;