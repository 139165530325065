import React, {useState, useEffect} from 'react';
import { Container, Button, Modal, Form} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from '../components/Header';
import Login from '../components/Login';
import FormSubmit from '../components/FormSubmit';

const serverConfig = require("../config/server.js");

const Welcome = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
  }

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {//on refresh clear session storage
        sessionStorage.clear();
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const handleCloseInvitePopup = () => {
    setInputs({});
    setShowInvitePopup(false);
  }
  const handleShowInvitePopup = () => setShowInvitePopup(true);
  
  const [proceedToOnboarding, setProceedToOnboarding] = useState(false);
  const [refId, setRefId] = useState('');

  const [inviteSentMessage, setInviteSentMessage] = useState('');
  const [showAlreadySentPopup, setShowAlreadySentPopup] = useState(false);
  const handleCloseAlreadySentPopup = () => setShowAlreadySentPopup(false);
  const handleShowAlreadySentPopup = () => setShowAlreadySentPopup(true);

  const [inputs, setInputs] = useState({});
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      //token:mToken, ...inputs, force_flag:0
      ...inputs, force_flag:0
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "create_invite",formdata)
    .then(response=>{
      console.log("Send Invite response:", response);
      console.log(response.data.ref_id);
      if(response.data.status === 200){
        handleCloseInvitePopup();
        setProceedToOnboarding(true);
        setRefId(response.data.ref_id);
      } else if(response.data.status === 210){
        setInviteSentMessage(response.data.message);
        handleShowAlreadySentPopup();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleProceedSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      //token:mToken, ...inputs, force_flag:1
      ...inputs, force_flag:1
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "create_invite",formdata)
    .then(response=>{
      console.log("Proceed with Send Invite response:", response);
      console.log(response.data.ref_id);
      if(response.data.status === 200){
        handleCloseAlreadySentPopup();
        handleCloseInvitePopup();
        setProceedToOnboarding(true);
        setRefId(response.data.ref_id);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  return(
    <>
      <Header isLoggedIn={false} />
      <main>
        <Container fluid>
          <div className='text-center'>
            <h4>Welcome to {window.bankName}</h4>
            <Button variant="transparent" className='btn-send-invite mt-5' onClick={handleShowInvitePopup} /* disabled={sendInviteDisabled} */>Send invite to new customer</Button>
            {proceedToOnboarding && <Button variant="transparent" className='btn-send-invite mt-5' onClick={() => navigate('/onboarding?refid='+refId)}>Proceed to Onboarding</Button>}

            <p className='mt-5' style={{textAlign:'justify'}}><small>This computer network belongs to Amberoon Inc. and may be used only by Amberoon employees and Customers only for work-related purposes. Amberoon Inc reserves the right to monitor use of this network to ensure network security and to respond to specific allegations of user misuse. Use of this network shall constitute consent to monitoring for such purposes. In addition, the Amberoon Inc reserves the right to consent to a valid law enforcement request to search the network for evidence of a crime stored within the network.</small></p>
            <p style={{textAlign:'justify'}}><small>This page is for demo purposes only. It will not be available in the actual product but will be made available as a feature in the workflow.</small></p>

            <Modal show={showInvitePopup} onHide={handleCloseInvitePopup} backdrop="static"keyboard={false} centered>
              <Form onSubmit={handleSendInvite}>
                <Modal.Header closeButton>
                  <Modal.Title>New Customer Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Name" name="name" value={inputs.name || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email" name="app_email_id" value={inputs.app_email_id || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Authorization Code" name="token" value={inputs.token || ""} onChange={handleChange} required />
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Mobile No." name="phone_number" value={inputs.phone_number || ""} onChange={handleChange} required />
                  </Form.Group> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="transparent" onClick={handleCloseInvitePopup}>Cancel</Button>
                  <Button variant="primary" type="submit" className='ms-3'>{/* <img src={require('./../assets/images/sent-invite-icon-white.png')} alt="Sent Invite Icon" className='me-2' /> */}Send Invite</Button>
                </Modal.Footer>
              </Form>
            </Modal>

            <Modal show={showAlreadySentPopup} onHide={handleCloseAlreadySentPopup} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Body>
                <p>{inviteSentMessage}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseAlreadySentPopup}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleProceedSendInvite}>Yes</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </main>
  </>
  )
}

export default Welcome;
