import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const Header = (props) => {
  const isLoggedIn = props.isLoggedIn;

  const [logoBase64Text, setLogoBase64Text] = useState('');

  useEffect(() => {
    console.log(window.location.hostname);
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.hostname})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const handleLogout = () => {
    sessionStorage.clear();
  }

  return (
    <header>
      <Navbar expand="md">
        <Container fluid className="justify-content-between">
          <Navbar.Brand href="/"><img src={logoBase64Text} alt={window.bankName} className="img-fluid" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav">
              {isLoggedIn ? <li><Nav.Link href="/" onClick={handleLogout}>Logout</Nav.Link></li> : <li><Nav.Link href="/">Log In</Nav.Link></li>}
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;