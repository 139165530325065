import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import Header from '../components/Header';
import { usePasswordValidation } from '../hooks/usePasswordValidation';
import { QRCode } from 'react-qrcode-logo';
import axios from "axios";
import { sha3_256 } from 'js-sha3';

const serverConfig = require("../config/server.js");

const Onboarding = () => {

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [emailid, setEmailid] = useState("");
  const [status, setStatus] = useState();
  const [statusMessage, setStatusMessage] = useState("");
  const [nextDisabled, setNextDisabled] = useState(true);

  const[errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "customer_email_get",{ref_id:searchParams.get("refid")}).then(response => response.data)
    .then((resData) => {
      console.log(resData);
      if(resData.status === 200){
        setStatus(true);
        setEmailid(resData.email_id);
        sessionStorage.setItem('emailid',resData.email_id);
        console.log(sessionStorage.getItem('emailid'));
      } else if(resData.status === 405){
        setStatus(false);
        setStatusMessage(resData.message);
      } else {
        setStatus(false);
        //setStatusMessage(resData.message);
        if(isMobile){
          navigate('/');
        }
      }
    })
    .catch(err=>console.log("error is",err));
  },[]);

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    match,
    specialChar,
  ] = usePasswordValidation({
    firstPassword: password.firstPassword,
    secondPassword: password.secondPassword,
  });

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  useEffect(() => {
    if(validLength && hasNumber && upperCase && lowerCase && match && specialChar) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  },[validLength, hasNumber, upperCase, lowerCase, match, specialChar]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //var formdata = JSON.stringify({password:password.firstPassword});
    let hpwd = sha3_256(emailid + password.firstPassword);
    console.log('hpwd:'+hpwd); //hashPassword(emailid + password.firstPassword));
    var formdata = {ref_id:searchParams.get("refid"), password_hash:hpwd};
    console.log(formdata);

    axios.post(serverConfig.api_base_url + "customer_password_set",formdata)
    .then(response=>{
      console.log("SET PASSWORD returned:", response);
      if(response.data.status === 401){
        setErrorMessage(response.data.message);
      } else {
        setTimeout(() => {
		let token = response.data.token;
		sessionStorage.setItem('token', token);
          navigate('/',{
		  state:{
			  stepnum:2,
			  token: token,
			  entityname: 'personal_information',
			  refid:searchParams.get("refid"),
        hpwd:hpwd,
        emailid:emailid
		  }});
        }, 500);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <Header />
      <BrowserView>
        <main className='text-center'>
          <Container fluid>
            <div className='wrapper'>
              <h1>New Customer Onboarding</h1>
              <div>
                <img src={require('./../assets/images/form-icon.png')} alt="Form" />
                <h2>Welcome and Thank You for your interest in {window.accountName}</h2>
              </div>
              {/* <div className='logged-in'>
                <span className='txt-red'>You have logged in as:</span>
                <strong>{emailid}</strong>
              </div> */}
              <h2>To continue with the onboarding form, <br/>you would need to scan the below <br/>QR code and proceed on your smart phone</h2>
              <div className='qr-code'><QRCode value={window.location.href} size="250" /></div>
            </div>
          </Container>
        </main>
      </BrowserView>
      <MobileView>
        <main>
          {status === true && <><Container fluid>
            <div className='page-title text-center'>
              <img src={require('./../assets/images/form-icon.png')} alt="Form" className="img-fluid" width="35" />
              <h1>Welcome and Thank You for your interest in {window.accountName}</h1>
            </div>
          </Container>
          <Container fluid className='step'>
            <div className='step-left'>
              <span className='step-title'><strong>You have logged in as:</strong></span>
              <span className="mt-0"><strong>{emailid}</strong></span>
            </div>
          </Container>
          <Form onSubmit={handleSubmit}>
            <Container fluid>
              <Form.Group controlId="password" className="mb-3">
                <div className='mb-2'>Enter your password:</div>
                <Form.Label>Password</Form.Label>
                <Form.Control name="password" type="password" onChange={setFirst} required />
              </Form.Group>
              <Form.Group controlId="confirmPwd" className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" onChange={setSecond} required />
              </Form.Group>
              <ul className='validation-check'>
                <li className={validLength ? 'yes' : 'no'}>Minimum 8 characters required</li>
                <li className={hasNumber ? 'yes' : 'no'}>Must have at least 1 number</li>
                <li className={upperCase ? 'yes' : 'no'}>Must have at least 1 uppercase</li>
                <li className={lowerCase ? 'yes' : 'no'}>Must have at least 1 lowercase</li>
                <li className={specialChar ? 'yes' : 'no'}>Must have at least 1 special character</li>
                <li className={match ? 'yes' : 'no'}>Password and Confirm Password must match</li>
              </ul>
              <p className="txt-red text-center">{errorMessage}</p>
            </Container>
            <Container fluid className='btn-container'>
              <Row>
                <Col>
                  <Button variant="primary" type="submit" id="submit_pwd" disabled={nextDisabled}>Next</Button>
                </Col>
              </Row>
            </Container>
          </Form></>}
          {status === false && <Container fluid>
            <div className='text-center mt-5'>
              <img src={require('./../assets/images/invalid-icon.png')} alt="Invalid" width="35" />
              <div className='mb-4 mt-2'>{statusMessage}</div>
            </div>
            <Button variant="primary" type="button" onClick={()=> window.location.href=window.bankUrl}>Contact {window.bankName}</Button>
          </Container>}
        </main>
      </MobileView>
    </>
  );
}

export default Onboarding;
