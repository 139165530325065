import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';

import Signup from './pages/Signup';
import Onboarding from './pages/Onboarding';
import DisclosuresHtml from './components/disclosuresHtml';
import ConsentHtml from './components/consentHtml';
import Welcome from './pages/Welcome';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Signup />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/disclosures" element={<DisclosuresHtml />} />
        <Route path="/consent" element={<ConsentHtml />} />
        <Route path="/invite-demo" element={<Welcome />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
