import React from "react";
import { Container} from 'react-bootstrap';
import Header from "./Header";
import ConsentCommunications from "./consentCommunication";

const ConsentHtml = () => {
  return (
    <>
      <Header />
      <main>
        <Container fluid>
          <div className='disclosures-html'><ConsentCommunications /></div>
        </Container>
      </main>
    </>
  );
};

export default ConsentHtml;